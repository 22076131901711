<div class="legal">
  <p>
    {new Date().getFullYear()} © Temporal Technologies. All Rights Reserved.
  </p>
  <a target="_blank" href="https://docs.temporal.io/privacy-policy"
    >Privacy Policy</a
  >
  <a href="/candidate-privacy-policy">Candidate Privacy Policy</a>
  <a href="/terms-of-service">Terms of Service</a>
  <a href="/code-of-conduct">Code of Conduct</a>
</div>

<style lang="postcss">
  .legal {
    @apply whitespace-nowrap flex max-lg:flex-col lg:flex-row lg:gap-4 max-lg:gap-1 text-xs font-normal font-sans text-indigo-400;

    & a:hover {
      @apply text-indigo-100 transition-colors;
    }
  }
</style>
